import React, {
  useContext, useEffect, useRef, useState,
} from "react";

import { useLazyQuery } from "@apollo/client";

import { SessionStatus, SessionType } from "@/autoGeneratedGlobalTypes";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";

import type { getActiveSession, getActiveSession_getActiveSession } from "./graphql/__generated__/getActiveSession";
import { getMyProfile } from "./graphql/__generated__/getMyProfile";
import { GET_ACTIVE_SESSION } from "./graphql/GET_ACTIVE_SESSION";
import { GET_PROFILE_QUERY } from "./graphql/GET_PROFILE_QUERY";
import SessionAlert from "./SessionAlert";

const SessionController = () => {
  const {
    userID,
    setUserID,
    isUserLoggedIn,
    setBalance,
    setFreeMinutesCount,
  } = useContext(UserContext);
  const {
    isGetActiveSessionLoopRunning,
    setIsGetActiveSessionLoopRunning,
    setIsExpert,
    setAlertStatus,
    setIsAlertShown,
  } = useContext(GlobalContext);

  // const abortControllerRef = React.useRef<AbortController>();

  const [getActiveSession, {
    loading: getActiveSessionLoading,
    error: getActiveSessionError,
    data: getActiveSessionData,
  }] = useLazyQuery<getActiveSession>(GET_ACTIVE_SESSION, { fetchPolicy: "no-cache" });//

  const [getProfile, {
    loading: profileLoading,
    error: profileError,
    data: profileData,
  }] = useLazyQuery<getMyProfile>(
    GET_PROFILE_QUERY,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    },
  );

  const [
    currentSessionData,
    setCurrentSessionData,
  ] = useState<getActiveSession_getActiveSession | null>();
  const activeSessionCheckTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isUserLoggedIn) {
      // todo: move getProfile to Template or hook
      if (userID === null) {
        getProfile();
      }
    }
    setIsGetActiveSessionLoopRunning(isUserLoggedIn);
  }, [userID, isUserLoggedIn, getProfile, setIsGetActiveSessionLoopRunning]);

  // Requests session data from server
  useEffect(() => {
    if (isGetActiveSessionLoopRunning) {
      if (!activeSessionCheckTimer.current && isUserLoggedIn) {
        // abortControllerRef.current = new window.AbortController();
        getActiveSession(
          // { context: { fetchOptions: { signal: abortControllerRef.current.signal } } },
        );
        activeSessionCheckTimer.current = setInterval(() => {
          // abortControllerRef.current = new window.AbortController();
          getActiveSession(
            // { context: { fetchOptions: { signal: abortControllerRef.current.signal } } },
          );
        }, 10000);
      }
    } else {
      if (activeSessionCheckTimer.current) {
        clearInterval(activeSessionCheckTimer.current);
        activeSessionCheckTimer.current = null;
      }
      // if (abortControllerRef.current) {
      //   abortControllerRef.current.abort();
      // }
      setCurrentSessionData(null);
    }
  }, [isGetActiveSessionLoopRunning, isUserLoggedIn, getActiveSession]);

  useEffect(() =>
    () => {
      if (activeSessionCheckTimer.current) {
        clearInterval(activeSessionCheckTimer.current);
        activeSessionCheckTimer.current = null;
      }
      // if (abortControllerRef.current) {
      //   abortControllerRef.current.abort();
      // }
      setCurrentSessionData(null);
    }, []);

  // useEffect(() => {
  //   abortControllerRef.current = new window.AbortController();
  // }, []);

  useEffect(() => {
    if (getActiveSessionData && !getActiveSessionLoading && !getActiveSessionError) {
      if (getActiveSessionData.getActiveSession) {
        setCurrentSessionData(getActiveSessionData.getActiveSession);
        setIsAlertShown(true);
      }
    }
  }, [getActiveSessionLoading, getActiveSessionError, getActiveSessionData, setIsAlertShown]);

  useEffect(() => {
    if (getActiveSessionData
      && !getActiveSessionLoading
      && !getActiveSessionError
      && !getActiveSessionData.getActiveSession
    ) {
      if (currentSessionData?.status === SessionStatus.waiting
          && currentSessionData.sessionType === SessionType.VIDEO_CALL
      ) {
        // todo: set these status somewhere else
        setAlertStatus("cancelled");
        setCurrentSessionData(null);
      } else {
        setCurrentSessionData(null);
      }
    }
  }, [getActiveSessionLoading, getActiveSessionError, getActiveSessionData, currentSessionData, setAlertStatus]);

  useEffect(() => {
    if (profileData && !profileLoading && !profileError) {
      setUserID(profileData.getMyProfile.user.id);
      setFreeMinutesCount(profileData.getMyProfile.user.freeMinutesLeft);
      setBalance(profileData.getMyProfile.balance);

      if (profileData.getMyProfile.isExpert) {
        setIsExpert(true);
        // todo: run first thing on the site + make refetch
        // todo: + move here all profile and setIsExpert data
      }
    }
  }, [
    profileLoading,
    profileError,
    profileData,
    setUserID,
    setFreeMinutesCount,
    setBalance,
    setIsExpert,
  ]);

  return (
    <SessionAlert
      sessionId={currentSessionData?.id}
      sessionStatus={currentSessionData?.status}
      expert={currentSessionData?.expert}
      client={currentSessionData?.client}
      sessionType={currentSessionData?.sessionType}
    />
  );
};

export default SessionController;
