import React from 'react'
import { Icon, IconTypeEnum } from '@/components/common/icon';
import { FormTypeEnum } from '../../modals/types';
import { setAuthParamToURL } from '../../modals/utils';
import useOnboardingForClient from '@/hooks/useOnboardingForClient';

const OnboardingBanner = () => {
  const { isActiveOnboarding } = useOnboardingForClient()

  return (
    <div>
       {isActiveOnboarding && 
        <div className="header__onboarding-banner" onClick={() => setAuthParamToURL(location, FormTypeEnum.Onboarding)}>
          <Icon type={IconTypeEnum.Time} />
        <p className='header__text-free-call'>Получите вашу <span>1-ю бесплатную консультацию</span></p>
      </div>}
    </div>
  )
}
export default OnboardingBanner

