import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { sendCode, sendCodeVariables } from "@layout/modals/graphql/__generated__/sendCode";
import { SEND_CODE } from "@layout/modals/graphql/SEND_CODE";
import { ErrorCodeEnum, FormTypeEnum, LoginChangeForm, LoginTypeEnum } from "@layout/modals/types";
import { getErrorByCode, setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import _uniqueId from "lodash/uniqueId";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import PhoneInput from "@/components/common/Inputs/phoneInput";
import AuthorizationFormContext from "@/contexts/AuthorizationForm/AuthorizationFormContext";
import UserContext from "@/contexts/User/UserContext";
import { useManageUtmData } from "@/hooks/useManageUtmData";
import { getErrorCode } from "@/utils/apolloUtils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { SEND_OTP_CODE_RETRY_DELAY } from "@components/constants";

const LoginByPhoneModal = (props: LoginChangeForm) => {
  const { clientReferrer } = useContext(UserContext);
  const { setPrevValueCallback } = props;
  const [sendCodeMutation, { data, loading, error }] = useMutation<sendCode, sendCodeVariables>(
    SEND_CODE,
    {
      context: {
        fetchOptions: {
          credentials: "include",
        },
      },
    },
  );
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [phone, setPhone] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const { sentCodeTimestamp, setLoginType, setSentCodeTimestamp } = useContext(AuthorizationFormContext);
  const { getUtmData } = useManageUtmData();
  const [inputUniqueId] = useState(_uniqueId());

  const handleSubmit = async () => {
    if (phoneErrorText) {
      return;
    }

    if (executeRecaptcha) {
      const token = await executeRecaptcha("submit");
      if (token) {
        window.carrotquest.identify({ $phone: phone });
        sendCodeMutation({
          variables: {
            phone,
            utm: getUtmData(),
            reCaptcha: token,
            referer: clientReferrer,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setErrorCode(null);
      setSentCodeTimestamp(Date.now() + SEND_OTP_CODE_RETRY_DELAY);
      setAuthParamToURL(location, FormTypeEnum.CodeFromSMS);
    }
  }, [data, loading, error, setSentCodeTimestamp, location]);

  useEffect(() => {
    if (error) {
      // If we get the StatusCodeTooManyRequests provide user to form with code
      if (getErrorCode(error) === ErrorCodeEnum.StatusCodeTooManyRequests) {
        if (!sentCodeTimestamp) {
          setSentCodeTimestamp(Date.now() + SEND_OTP_CODE_RETRY_DELAY);
        }
        setAuthParamToURL(location, FormTypeEnum.CodeFromSMS);
      } else {
        setErrorCode(getErrorCode(error));
      }
    }
  }, [error, location, sentCodeTimestamp, setSentCodeTimestamp]);

  useEffect(() => {
    setLoginType(LoginTypeEnum.LoginByPhone);
  }, [setLoginType]);

  const onPhoneChanged = (changedPhone: string, changedErrorText: string | null) => {
    setPhone(changedPhone);
    setPhoneErrorText(changedErrorText);
  };

  return (
    <>
      <div className="input-group">
        <div className="input-group__item">
          <PhoneInput
            id={inputUniqueId}
            phoneInitial={phone}
            setPrevValueCallback={setPrevValueCallback}
            onChange={onPhoneChanged}
            onSubmit={handleSubmit}
          />
        </div>
        {phone && phoneErrorText !== null && (
          <div className="input-group__error">{phoneErrorText}</div>
        )}
        {errorCode && <div className="input-group__error">{getErrorByCode(errorCode)}</div>}
      </div>
      <Button
        text="Получить код в СМС"
        isLoading={loading}
        size={ButtonSizeEnum.Large}
        color={ButtonColorEnum.Dark}
        onClick={handleSubmit}
        disabled={!phone || !!phoneErrorText}
      />
      <div className="auth__text">
        <b onClick={() =>
          setAuthParamToURL(location, FormTypeEnum.LoginAsExpert)}
        >
          Войти как эксперт
        </b>
      </div>
      <div className="auth__text">
        Создавая аккаунт, вы соглашаетесь
        {" "}
        <br />
        {" "}
        с&nbsp;
        <a href="/user-agreement" target="_blank" rel="noreferrer">
          <b>Условиями,</b>
        </a>
        {"\n"}
        <a href="/privacy-policy" target="_blank" rel="noreferrer">
          <b>Политикой конфиденциальности</b>
        </a>
        {"\n"}
        и&nbsp;
        <a href="/rassylka-agree" target="_blank" rel="noreferrer">
          <b>Получением рассылок</b>
        </a>
        {"\n"}
        {process.env.GATSBY_WEBSITE_NAME}
      </div>
    </>
  );
};

export default LoginByPhoneModal;
