import React, { useContext, useEffect } from "react";

import { Helmet } from "react-helmet";

import { SessionStatus, SessionType } from "@/autoGeneratedGlobalTypes";
import GlobalContext from "@/contexts/Global/GlobalContext";

import AlertHoc from "./alertHoc";
import type { SessionAlertProps } from "./types";

const SessionAlert = (props: SessionAlertProps) => {
  const {
    sessionId, client, expert, sessionStatus, sessionType,
  } = props;
  const {
    isAlertShown,
    alertStatus, // todo: support alertStatus-es except sessionStatus
  } = useContext(GlobalContext);

  if (!isAlertShown
    || (!sessionStatus && !alertStatus)
    || sessionType === SessionType.PHONE_CALL
    || (sessionType === SessionType.CHAT && sessionStatus === SessionStatus.in_progress)
  ) {
    return null;
  }

  return (
    <AlertHoc
      alertStatus={sessionStatus || alertStatus}
      client={client}
      expert={expert}
      sessionId={sessionId}
      sessionType={sessionType}
    />
  );
};

export default SessionAlert;
