import React, { useState } from "react";
import { popularQuestionsData } from "../../constants";
import { PopularQuestionsProps } from "./types";

import "./styles.scss";
import { PopularQuestionsData } from "../../types";

const PopularQuestions = ({ checkedThemeId, nextStep }: PopularQuestionsProps) => {
  const [checkedQuestionId, setCheckedQuestionId] = useState<number | undefined>();

  const questionsKey = `questions_${checkedThemeId}` as keyof PopularQuestionsData;
  const questions = popularQuestionsData[questionsKey];

  const clickQuestionCardHandler = (questionId: number) => {
    setCheckedQuestionId(questionId);
    nextStep();
  };

  return (
    <div className="popular-questions">
      <p className="onboarding-title">Популярные вопросы <br/> на тему «{questions.categoryName}»</p>

      <div className="popular-questions__list-container">
        {questions.questions.map(({ question, id }) => {
          return (
            <div
              className="popular-questions__list-item"
              onClick={() => clickQuestionCardHandler(id)}
            >
              <input
                className="big"
                type="radio"
                name="question"
                checked={id === checkedQuestionId}
              />
              <p>{question}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopularQuestions;
