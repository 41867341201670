import React, { Fragment } from "react";
import { questions } from "../../constants";
import OnboadingCard from "../onboadingCard";
import { QuestionTopicProps } from "./types";

import "./styles.scss";

const QuestionTopic = ({ nextStep, setCheckedThemeId }: QuestionTopicProps) => {
  return (
    <div className="question-topic">
      <p className="onboarding-title">Какую тему хотите обсудить?</p>
      <div className="question-topic__block-cards">
        {questions.step2.map((item, index) => (
          <Fragment key={index}>
            <OnboadingCard
              text={item.text}
              iconType={item.icon}
              nextStep={nextStep}
              setClickElementId={setCheckedThemeId}
              idElement={index + 1}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default QuestionTopic;
