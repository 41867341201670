import React from "react";

import { navigate } from "gatsby";

import { Button } from "@/components/common/button";

import "./styles.scss";

const FinalModalContent = () =>
  (
    <div className="final-modal-content">
      <p className="onboarding-title">Первая консультация бесплатно</p>
      <div className="final-modal-content__block-content">
        <p className="final-modal-content__description">
          Получите точный ответ на вопрос у
          <br />
          идеально подобранного для вас
          <br />
          эксперта
        </p>
        <Button
          text="Получить ответ"
          onClick={() =>
            navigate("/catalog", { state: { onboardingFlag: true } })}
        />
      </div>
    </div>
  );

export default FinalModalContent;
