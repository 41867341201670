import React, { Fragment } from "react";
import { questions } from "../../constants";
import OnboadingCard from "../onboadingCard";
import { QuestionAboutFeelingProps } from "./types";

import "./styles.scss";

const QuestionAboutFeeling = ({
  nextStep,
  feelingAnswer,
  setFeelingAnswer,
}: QuestionAboutFeelingProps) => {
  return (
    <div className="question-feeling">
      <p className="onboarding-title">Как вы себя чувствуете сегодня?</p>
      <div className="question-feeling__block-cards">
        {questions.step1.map((item, index) => (
          <Fragment key={index}>
            <OnboadingCard
              text={item.text}
              iconType={item.icon}
              nextStep={nextStep}
              setClickElementId={setFeelingAnswer}
              idElement={index + 1}
              feelingAnswer={feelingAnswer}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default QuestionAboutFeeling;
