import { createContext } from "react";

import { GlobalContextType } from "@/contexts/Global/types";

const GlobalContext = createContext<GlobalContextType>({
  isAlertShown: false,
  setIsAlertShown: () => {},
  setAlertStatus: () => {},
  alertStatus: null,
  isGetActiveSessionLoopRunning: false,
  setIsGetActiveSessionLoopRunning: () => {},
  isExpert: false,
  setIsExpert: () => {},
  isOpenOnpoardingModal: false,
  setIsOpenOnpoardingModal: () => {}
});

export default GlobalContext;
