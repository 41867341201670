import React from "react";

import FinalModalContent from "../finalModalContent";
import PopularQuestions from "../popularQuestions";
import ProcessFindResult from "../processFindResult";
import QuestionAboutFeeling from "../questionFeeling";
import QuestionTopic from "../questionTopic";

interface StepContentProps {
  step: number;
  nextStep: () => void;
  setCheckedThemeId: (id: number) => void;
  checkedThemeId: number | undefined;
  feelingAnswerId: number | undefined;
  setFeelingAnswerId: (id: number) => void;
}

const StepContent: React.FC<StepContentProps> = ({
  step,
  nextStep,
  setCheckedThemeId,
  checkedThemeId,
  feelingAnswerId,
  setFeelingAnswerId,
}) => {
  switch (step) {
    case 1:
      return (
        <QuestionAboutFeeling
          nextStep={nextStep}
          feelingAnswer={feelingAnswerId}
          setFeelingAnswer={setFeelingAnswerId}
        />
      );
    case 2:
      return <QuestionTopic nextStep={nextStep} setCheckedThemeId={setCheckedThemeId} />;
    case 3:
      return <PopularQuestions nextStep={nextStep} checkedThemeId={checkedThemeId} />;
    case 4:
      return <ProcessFindResult nextStep={nextStep} />;
    case 5:
      return <FinalModalContent />;
    default:
      return <div>Неизвестный шаг</div>;
  }
};

export default StepContent;
