import React, { useContext, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { navigate } from "gatsby";

import { setRedirectUrlSessionStorage } from "@/account/redirect";
import { SessionType } from "@/autoGeneratedGlobalTypes";
import { END_SESSION } from "@/components/chat/graphql/END_SESSION";
import { Button, ButtonColorEnum, ButtonIconPositionEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { UserAvatar } from "@/components/common/userAvatar";
import { UserAvatarTypeEnum } from "@/components/common/userAvatar/types";
import UserInfo from "@/components/common/userInfo";
import GlobalContext from "@/contexts/Global/GlobalContext";
import { isBrowser } from "@/utils/env";

import { WaitingAlertProps } from "./types";

import "../../styles.scss";
import "./styles.scss";

const WaitingAlert = (props: WaitingAlertProps) => {
  const { client, expert, isExpert, sessionId, sessionType } = props;
  const {
    setIsAlertShown,
    setAlertStatus,
  } = useContext(GlobalContext);

  const { voxImplantInfo: { userName: clientVIName = null } = {} } = client || {};
  const { voxImplantInfo: { userName: expertVIName = null } = {} } = expert || {};
  const receiver = isExpert ? client : expert;

  const handleButtonClick = (e: React.MouseEvent) => {
    if (isBrowser()) {
      e.preventDefault();

      setRedirectUrlSessionStorage(window.location.pathname);
      if (sessionType === SessionType.CHAT) {
        navigate(`/textChat/?sessionId=${sessionId}`);
      } else {
        navigate(`/chat/?sessionId=${sessionId}`);
      }
    }
  };

  const [
    endSession,
    { loading: endSessionIsLoading, error: endSessionError, data: endSessionData },
  ] = useMutation(END_SESSION);

  useEffect(() => {
    if (!endSessionIsLoading && !endSessionError && endSessionData) {
      const { durationInMinutes, totalPrice } = endSessionData;

      setAlertStatus(null);
      setIsAlertShown(false);

      if (!durationInMinutes) {
        return;
      }
      // todo: check if redirect is needed
      setRedirectUrlSessionStorage(window.location.pathname);
      navigate("/chat/review", {
        state: {
          receiver,
          durationInMinutes,
          totalPrice,
          sessionID: sessionId,
          sessionType: "waiting", // todo: replace with enum
          isExpert,
        },
      });
    }
  }, [endSessionData, endSessionError, endSessionIsLoading,
    isExpert, receiver, sessionId, setAlertStatus, setIsAlertShown]); // todo: fix

  return (
    <>
      <div className="modal__top" />
      <div
        className="modal__content alert__modal alert-waiting"
      >
        {receiver && (
          <UserInfo
            avatar={(
              <UserAvatar
                mobileImage={receiver.mobileImage}
                desktopImage={receiver.desktopImage}
                name={receiver.name}
                userId={receiver.id}
                type={UserAvatarTypeEnum.Call}
              />
            )}
          >
            <div className="alert__modal-icon alert-waiting__modal-icon">
              {sessionType === SessionType.CHAT ? <Icon type={IconTypeEnum.ChatIncoming} size={IconSizeEnum.Size48} viewBox="46" />
                : <Icon type={IconTypeEnum.CallIncoming} size={IconSizeEnum.Size48} />}
            </div>
          </UserInfo>
        )}
        <h2 className="h2">
          {isExpert ? "Клиент " : "Эксперт "}
          {" "}
          ожидает вас
        </h2>
        <div className="alert__modal-buttons">
          <div className="alert__modal-button">
            <a onClick={handleButtonClick} href={`/chat/?sessionId=${sessionId}`}>
              <Button
                text="Начать консультацию"
                icon={IconTypeEnum.Right}
                iconPosition={ButtonIconPositionEnum.Right}
              />
            </a>

          </div>
          {!isExpert && (
            <div className="alert__modal-button">
              <Button
                text="Завершить"
                color={ButtonColorEnum.White}
                onClick={() =>
                  endSession({ variables: { sessionID: sessionId } })}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WaitingAlert;
